const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;

const CommCoachs = USER_TYPE && USER_TYPE.toUpperCase() === 'SCHOOL' ? '/CommSchoolCoachs.json' : '/CommCoachs.json';

const settings = {};

const api = {
  adminLogin: '/checklogin',
  resetPassword: '/api/reset-password',
  newPassword: '/api/new-password',
  verifyOtp: '/api/direct/su/verify-otp',
  bulkUpload: process.env.REACT_APP_DIRECT_URL + '/su/bulk-upload',
  templateDownload: process.env.REACT_APP_DIRECT_URL + '/su/template-download',
  staffEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-staff',
  parentEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-parent',
  studentEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-student',
  schoolRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-school',
  susRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-sus',
  parentRegister: process.env.REACT_APP_DIRECT_URL + '/su/register-parent',
  schoolDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-school',
  staffDelete: process.env.REACT_APP_DIRECT_URL + '/su/staff-delete',
  susDelete: process.env.REACT_APP_DIRECT_URL + '/su/delete-sus',
  parentDelete: process.env.REACT_APP_DIRECT_URL + '/su/parent-delete',
  schoolStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/status-change',
  coachStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/coach-status-change',
  parentStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/parent-activate',
  suAdminStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-status-change',
  testStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/test-status-change',
  staffStatusChange: process.env.REACT_APP_DIRECT_URL + '/su/staff-status-change',
  suAdminDelete: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-delete',
  suAdminAdd: process.env.REACT_APP_DIRECT_URL + '/su/suadmin-add',
  staffAdd: process.env.REACT_APP_DIRECT_URL + '/su/staff-add',
  testAdd: process.env.REACT_APP_DIRECT_URL + '/su/test-add',
  testEdit: process.env.REACT_APP_DIRECT_URL + '/su/edit-test',
  testDelete: process.env.REACT_APP_DIRECT_URL + '/su/test-delete',
  testApprove: process.env.REACT_APP_DIRECT_URL + '/su/test-approve',
  studentAdd: process.env.REACT_APP_DIRECT_URL + '/su/student-add',
  studentDelete: process.env.REACT_APP_DIRECT_URL + '/su/student-delete',
  profileEdit: process.env.REACT_APP_DIRECT_URL + '/su/profile-edit',
  countriesUpdate: process.env.REACT_APP_DIRECT_URL + '/su/countries-update',
  studentVerfiy: process.env.REACT_APP_DIRECT_URL + '/su/verify-child',
  getHome: process.env.REACT_APP_API_URL + '/CommHome.json',
  getWelcome: process.env.REACT_APP_API_URL + '/CommWelcome.json',
  getSchool: process.env.REACT_APP_API_URL + '/CommSchool.json',
  getSchools: process.env.REACT_APP_API_URL + '/CommSchools.json',
  getStaff: process.env.REACT_APP_API_URL + '/CommStaff.json',
  getStaffs: process.env.REACT_APP_API_URL + '/CommStaffs.json',
  getCoachs: process.env.REACT_APP_API_URL + CommCoachs,
  getCoach: process.env.REACT_APP_API_URL + '/CommCoach.json',
  getParents: process.env.REACT_APP_API_URL + '/CommParents.json',
  getParent: process.env.REACT_APP_API_URL + '/CommParent.json',
  getTests: process.env.REACT_APP_API_URL + '/CommTests.json',
  getTest: process.env.REACT_APP_API_URL + '/CommTest.json',
  getTestsToApprove: process.env.REACT_APP_API_URL + '/CommTestsToApprove.json',
  getTestToApprove: process.env.REACT_APP_API_URL + '/CommTestToApprove.json',
  getProfile: process.env.REACT_APP_API_URL + '/CommProfile.json',
  getProfileFields: process.env.REACT_APP_API_URL + '/CommProfileFields.json',
  getStudents: process.env.REACT_APP_API_URL + '/CommStudents.json',
  getStudent: process.env.REACT_APP_API_URL + '/CommStudent.json',
  getSuAdmins: process.env.REACT_APP_API_URL + '/CommSuAdmins.json',
  getSuAdmin: process.env.REACT_APP_API_URL + '/CommSuAdmin.json',
  getCountries: process.env.REACT_APP_API_URL + '/CommCountries.json',
  getAssets: process.env.REACT_APP_API_URL + '/CommResourceBank.json',
  getAssetFilters: process.env.REACT_APP_API_URL + '/CommAssetFilters.json',
  getAssetTags: process.env.REACT_APP_API_URL + '/CommAssetTags.json',
  getAssetDirectoryTree: process.env.REACT_APP_API_URL + '/CommAssetDirectoryTree.json',
  getAssetItemDetail: process.env.REACT_APP_API_URL + '/CommAssetItemDetail.json',
  getAssetSharableLink: '/dad/api/v1/share/get-sharable-link',
  getQuiz: process.env.REACT_APP_API_URL + '/CommQuiz.json',
  submitQuiz: process.env.REACT_APP_DIRECT_URL + '/su/submit-quiz',
  getCertificates: process.env.REACT_APP_API_URL + '/CommCertificates.json',
  certificateDelete: process.env.REACT_APP_DIRECT_URL + '/su/remove-coach-file',
  certificateUpload: process.env.REACT_APP_DIRECT_URL + '/su/upload-coach-certificates',
  getGalleryItems: process.env.REACT_APP_API_URL + '/CommGalleryItems.json',
  galleryItemDelete: process.env.REACT_APP_DIRECT_URL + '/su/remove-coach-file',
  galleryItemUpload: process.env.REACT_APP_DIRECT_URL + '/su/upload-coach-gallery',
  getYoutubeLinks: process.env.REACT_APP_API_URL + '/CommYoutubeLinks.json',
  youtubeLinkDelete: process.env.REACT_APP_DIRECT_URL + '/su/remove-coach-file',
  youtubeLinkAdd: process.env.REACT_APP_DIRECT_URL + '/su/upload-coach-video',
  getLocations: process.env.REACT_APP_API_URL + '/CommLocations.json',
  locationDelete: process.env.REACT_APP_DIRECT_URL + '/su/remove-coach-places',
  locationUpload: process.env.REACT_APP_DIRECT_URL + '/su/upload-coach-places',
  getBatches: process.env.REACT_APP_API_URL + '/CommBatches.json',
  getBatchTests: process.env.REACT_APP_API_URL + '/CommBatchTests.json',
  updateBatch: process.env.REACT_APP_DIRECT_URL + '/su/update-batch',
};

export { api, settings };
