import React, { Component } from 'react';
import { titleUpdate } from './helpers';
import Welcome from './../components/Welcome';

class Dashboard extends Component {
  render() {
    return (
      <>
        {titleUpdate('navigation.dashboard')}
        <Welcome />
      </>
    );
  }
}

export default Dashboard;
