import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
//import Quiz from 'react-quiz-component';
import Drawer from './../components/Drawer';
import Footer from './../components/Footer';
import Form from './../components/ParentCreateForm';
import Breadcrumb from './../components/Breadcrumb';
import Quiz from './../components/Quiz';
import { withTranslation } from 'react-i18next';
//import { quiz } from '../components/quiz';

class TakeTest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      isStarted: false,
      data: [],
    };
  }

  handleTakeTest = () => {
    this.setState({ isStarted: true });
  };

  render() {
    const { t } = this.props;
    const { isStarted } = this.state;

    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title>
              {t('general.sportunleash')} - {t('take_test.title')}
            </title>
          </Helmet>
          <div className="container mx-auto lg:flex  bg-bg">
            <Drawer />
            <div className="w-full md:w-[calc(100%-84px)] 2xl:w-full md:ml-[84px] 2xl:ml-0 md:p-4 lg:p-8 min-h-screen relative pb-[90px] lg:pb-[100px] md:pb-[100px]">
              <Breadcrumb />
              <h1 className="text-2xl md:text-3xl font-bold italic mb-8">{t('take_test.title')}</h1>
              {!isStarted ? (
                <div className="text-body">
                  <div className="text-xl mb-4 italic ">{t('take_test.intro')}</div>
                  <div className="text-base mb-4">{t('take_test.description')}</div>
                  <div className="text-base mb-4">{t('take_test.description_extra')}</div>
                  <div className="text-lg mb-4 text-primary">{t('take_test.description_bottom')}</div>
                  <button onClick={this.handleTakeTest} className="inline-block btn bg-primary shadow-md py-3 px-3 md:px-5 rounded-md text-white hover:shadow-lg hover:opacity-80">
                    {t('take_test.start')}
                  </button>
                </div>
              ) : (
                <Quiz />
              )}

              {/*  <Quiz quiz={quiz} showDefaultResult={false} continueTillCorrect={true} disableSynopsis={true} /> */}
              <Footer />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withTranslation()(TakeTest);
