import React, { Component } from 'react';
import axios from 'axios';
import { api } from '../Settings';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
const IS_SUS = userData && userData.data.is_sus;
let ELIGIBLE = userData && userData.data.eligibility;
let TEST_ACCESS = userData && USER_TYPE.toUpperCase() === 'COACH' && ELIGIBLE && !IS_SUS;

//const { t, i18n } = useTranslation();

class Welcome extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {},
      hide: false,
      isLoading: false,
      isError: false,
      TEST_ACCESS: TEST_ACCESS,
    };

    this.hideContent = this.hideContent.bind(this);
    const visited = document.cookie
      .split('; ')
      .find((row) => row.startsWith('visited='))
      ?.split('=')[1];

    if (visited) {
      this.state.hide = true;
    }
  }

  componentDidMount() {
    const that = this;
    that.setState({ isLoading: true, isError: false });
    axios
      .get(api.getWelcome)
      .then((response) => {
        this.setState({
          data: response.data,
        });
        this.setState({ isLoading: false });
      })
      .catch(function (error) {
        that.setState({ isLoading: false, isError: true });
      });

    if (USER_TYPE?.toUpperCase() === 'COACH') {
      let uData = JSON.parse(localStorage.getItem('_userData'));
      let ELIGIBLE = uData && uData.data.eligibility;
      let TEST_ACCESS = uData && USER_TYPE.toUpperCase() === 'COACH' && ELIGIBLE && !IS_SUS;
      that.setState({ TEST_ACCESS: TEST_ACCESS });
    }
  }

  hideContent(e) {
    this.setState({ hide: true });
    document.cookie = 'visited=true; Secure';
  }

  render() {
    const { data, TEST_ACCESS } = this.state;
    const { t } = this.props;

    data.title = t('welcome.title');
    data.description = t('welcome.description');
    data.image = '/su-portal/images/welcome.png';

    return (
      <>
        {data && (
          <div className={`welcome overflow-hidden transition-all duration-300 bg-primary rounded-2xl min-h-[320px] ${this.state.hide ? 'max-h-0' : 'max-h-[1000px] relative'}`}>
            <div className={`pt-8 pb-8 p-4 2xl:p-16 text-white relative flex`}>
              <div className="w-7/12 pr-8">
                <h1 className="text-2xl md:text-5xl mb-8 font-semibold">{data.title}</h1>
                <div className="text-base mb-4" dangerouslySetInnerHTML={{ __html: data.description }}></div>

                {TEST_ACCESS && (
                  <div className="mt-8">
                    <Link to={'/take-a-test'} className="inline-block btn bg-white shadow-md py-3 px-3 md:px-5 rounded-md text-primary hover:shadow-lg hover:opacity-80">
                      {t('welcome.take_test')}
                    </Link>
                  </div>
                )}
              </div>

              <div className="w-5/12">
                {/*  <img className="logo ml-auto w-auto max-h-[225px]" src={data.image} /> */}
                <div className="welcome-image w-full ml-auto h-[225px]"></div>
              </div>
            </div>
            {/*  {
            EDIT_ACCESS && data.editLink &&
            <a href={data.editLink} target="_blank" className='absolute right-0 top-0 z-[1] underline  bg-primary text-white px-2 py-1 hover:bg-primary/70 text-sm'><FiEdit className='text-lg' title="Edit" /></a>
          } */}
          </div>
        )}
      </>
    );
  }
}

export default withTranslation()(Welcome);
