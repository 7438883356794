import React, { useEffect } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { CurrentTitle } from '../../atoms';
import { useRecoilState } from 'recoil';
import { useTranslation } from 'react-i18next';

export const TitleComp = () => {
  const { t, i18n } = useTranslation();
  const [titleState, setTitleState] = useRecoilState(CurrentTitle);

  useEffect(() => {}, [titleState]);

  return (
    <HelmetProvider>
      <Helmet>
        <title>
          {t('general.sportunleash')} - {t(titleState)}
        </title>
      </Helmet>
    </HelmetProvider>
  );
};
