import axios from 'axios';
import { api } from '../Settings';

const userData = JSON.parse(localStorage.getItem('_userData'));
const COACH_ID = userData && userData.data.coach_id;

export const getQuiz = async () => {
  try {
    const request = await axios(api.getQuiz, { params: { coach_id: COACH_ID } }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });
    const response = request.data;
    //console.log(response);
    return response;
  } catch (error) {
    console.log(`getQuiz error: ${error}`);
    return false;
  }
};
