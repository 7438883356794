import axios from 'axios';
//import { uriSerialized } from "../utils/uriSerialized"
import { api } from '../Settings';

export const getParentDatatable = async (q = null) => {
  try {
    const cols = {};
    let order = null;
    let sort = null;
    let search = null;

    if (q.search) {
      search = q.search;
    }

    if (q.order.length && q.order[0].id) {
      order = q.order.length && q.order[0].id && q.order[0].desc ? 'desc' : 'asc';
      sort = q.order.length && q.order[0].id ? q.order[0].id : null;
    }

    q?.filters.map((filter, i) => {
      if (filter.value) {
        cols[filter.key] = filter.value;
      }
    });

    const request = await axios(api.getParents, {
      params: { page: q.page, per_page: q.per_page, sort: sort, sort_by: order, cols: cols, search: search },
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    //const query = queryOptions ? "?" + uriSerialized(queryOptions) : ""
    //const request = await axios(api.getParents + '' + query,  { params: null }).catch((err) => console.log(err));

    const response = request.data.data;
    return response;
  } catch (error) {
    console.log(`getParents error: ${error}`);
    return false;
  }
};
