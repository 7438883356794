import { ArrowLongDownIcon, ArrowLongUpIcon, FunnelIcon } from '@heroicons/react/24/outline';
import { ClockIcon } from '@heroicons/react/24/solid';
import React from 'react';
import { useAsyncDebounce, useFilters, useGlobalFilter, usePagination, useSortBy, useTable } from 'react-table';
import { BiDownload, BiSearch } from 'react-icons/bi';
import { MdArrowDropDown } from 'react-icons/md';
import { CSVLink } from 'react-csv';
import { Popover } from '@headlessui/react';
import Flags from 'country-flag-icons/react/3x2';
import CountryListing from './../components/CountryListing';
import { api } from '../Settings';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

function TablePagination({ filters, columns, data, fetchData, countries, selectedIds, loading, pageCount: controlledPageCount, totalRow, actions: Actions }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    state: { pageIndex, pageSize, globalFilter, sortBy, filterObj },
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      manualPagination: true,
      manualGlobalFilter: true,
      manualSortBy: true,
      initialState: {
        pageIndex: 0,
        pageSize: 25,
      }, // Pass our hoisted table state
      pageCount: controlledPageCount,
      autoResetSortBy: false,
      autoResetExpanded: false,
      autoResetPage: false,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const GlobalFilter = ({ preGlobalFilteredRows, globalFilter, setGlobalFilter }) => {
    const count = preGlobalFilteredRows;
    const [value, setValue] = React.useState(globalFilter);
    const onChange = useAsyncDebounce((value) => {
      setGlobalFilter(value || undefined);
    }, 700);

    return (
      <div className={`mt-4 md:mt-0 shadow relative overflow-hidden rounded-md border border-black/10  bg-white w-full sm:w-96 ${loading && 'opacity-50 pointer-events-none'}`}>
        {Actions !== undefined ? <Actions /> : null}
        <BiSearch className="absolute left-2.5 top-3 text-xl text-icon" />
        <input
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={t('table.search')}
          type="search"
          className={`pl-10 p-2.5 outline-none w-full ${Actions !== undefined ? '' : 'self-end'}`}
        />
      </div>
    );
  };

  // console.log(filters);
  const [trggierFilter, setFilterChange] = React.useState([]);
  const [filterData, setFilters] = React.useState();
  const [filtered, setFilterStatus] = React.useState(false);
  const { t, i18n } = useTranslation();

  React.useEffect(() => {
    setFilters(filters);
  }, [countries]);

  React.useEffect(() => {
    let search = globalFilter === undefined ? '' : globalFilter;
    fetchData(pageSize, pageIndex, search, sortBy, trggierFilter);
  }, [fetchData, pageIndex, pageSize, globalFilter, sortBy, trggierFilter]);

  const SeletFilterItem = (f, o, close) => {
    close();
    const filter = filterData.filter((item) => item.key === f.key);
    filter[0].value = o.id;
    filter[0].selected = o.name;

    setFilters(filterData);
    fetchData(pageSize, pageIndex, '', sortBy, filterData);

    setFilterChange(filterData);
    setFilterStatus(true);
  };

  const chooseCountries = (data, close) => {
    close();
    //console.log(data);

    const filter = filterData.filter((item) => item.key === 'country');
    filter[0].value = data.id;
    filter[0].selected = data.name;

    setFilters(filterData);
    fetchData(pageSize, pageIndex, '', sortBy, filterData);

    setFilterChange(filterData);
    setFilterStatus(true);
  };

  const clearFilter = () => {
    filterData.map((item) => {
      item.value = null;
      item.selected = null;
    });

    setFilters(filterData);
    fetchData(pageSize, pageIndex, '', sortBy, []);
    setFilterStatus(false);
    setFilterChange(filterData);
  };

  const download = () => {
    console.log('selectedIds');
    console.log(selectedIds);

    axios
      .post(api.testExport, { test_id: selectedIds })
      .then(function (response) {
        let data = response.data;
        console.log(data);
        /* if(data.success ){
                    that.setState({ successMessage: data.message });
                    that.clearForm();
                }else{
                    that.setState({  apiError: data.message });
                }
    
                that.setState({  isSubmitting: false }); */
      })
      .catch(function (error) {
        //that.setState({  apiError: 'Unable to connect with the api.' });
        console.log(error);
      });

    /* filterData.map(item =>{
            item.value = null;
            item.selected = null;
        })

        setFilters(filterData)
        fetchData(pageSize, pageIndex, "", sortBy, [] )
        setFilterStatus(false);
        setFilterChange(filterData); */
  };

  return (
    <>
      <div className="info-wrp flex flex-wrap justify-between  mb-8">
        <div className="flex items-center">
          {filterData?.map((filter, index) => {
            return (
              <React.Fragment key={index}>
                <Popover className="relative z-10">
                  {({ open }) => (
                    <>
                      <Popover.Button className="flex items-center mr-4 relative outline-none">
                        <div className="menu-icon text-xl text-icon">{filter.icon}</div>
                        <div className="menu-text text-sm ml-1.5 ">{filter.selected ? filter.selected : filter.label}</div>
                        <MdArrowDropDown className={`text-lg text-body/50 transition-all duration-300  ${open ? 'rotate-180' : ''}`} />
                      </Popover.Button>

                      <Popover.Panel className="filter-popup bg-white absolute left-0 top-6 z-10 shadow-2xl rounded-lg p-3 space-y-1 max-h-[300px] overflow-auto max-w-[300px] ">
                        {({ close }) =>
                          filter.key === 'country' ? (
                            <div className="w-48 h-[240px]">
                              <CountryListing
                                chooseCountries={chooseCountries}
                                close={close}
                                menuIsOpen={true}
                                defaultMenuIsOpen={true}
                                openMenuOnFocus={true}
                                maxMenuHeight={190}
                                closeMenuOnSelect={false}
                                isMulti={false}
                                className="filter-drop"
                              />
                            </div>
                          ) : (
                            filter.options?.map((opt, index) => {
                              const Flag = Flags[opt.alpha_2?.toUpperCase()];

                              return (
                                <span
                                  onClick={() => SeletFilterItem(filter, opt, close)}
                                  key={index}
                                  data-key={filter.key}
                                  data-label={opt.name}
                                  data-id={opt.id}
                                  className="whitespace-nowrap text-sm font-medium cursor-pointer hover:text-primary text-ellipsis overflow-hidden flex items-center"
                                >
                                  {Flag && <Flag title={opt.name} className="min-w-[24px] w-5 h-auto mr-2" />}
                                  {opt.name}
                                </span>
                              );
                            })
                          )
                        }
                      </Popover.Panel>
                    </>
                  )}
                </Popover>
              </React.Fragment>
            );
          })}

          {filtered && (
            <span onClick={clearFilter} className="text-primary cursor-pointer ml-8">
              {t('table.filter-clear')}
            </span>
          )}
        </div>

        <div className="flex items-center ml-auto ">
          {selectedIds && selectedIds.length > 0 && (
            <span title="Download Selected Tests" className="bg-primary/10 hover:bg-primary/20 text-primary px-4 py-2 rounded-md flex items-center text-sm md:mr-2 cursor-pointer" onClick={(e) => download(e)}>
              <BiDownload className="text-lg mr-1" /> {t('table.download')}
            </span>
          )}

          <CSVLink title="Export Table Data" data={data} filename={'su-data.csv'} className="bg-primary/10 hover:bg-primary/20 text-primary px-4 py-2 rounded-md flex items-center text-sm md:mr-4">
            <BiDownload className="text-lg mr-1" /> <span className="hidden md:block">{t('table.export')}</span>
          </CSVLink>
        </div>
        <GlobalFilter preGlobalFilteredRows={totalRow} globalFilter={globalFilter} setGlobalFilter={setGlobalFilter} />
      </div>

      <div className="text-sm bg-white shadow-md rounded-lg mb-4 relative text-body">
        <table {...getTableProps()} className="table w-full">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className={`${column.class ? column.class : ''} border-b border-black/10 py-3 px-2 ${index === headerGroup.headers.length - 1 ? 'text-right pr-4' : 'text-left'}  ${index === 0 ? 'pl-4' : ''}`}
                  >
                    <div className="flex items-center">
                      {column.render('Header')}
                      {!column.disableSortBy && (
                        <span>
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <ArrowLongDownIcon className="h-3 w-3 inline ml-1" />
                            ) : (
                              <ArrowLongUpIcon className="h-3 w-3 inline ml-1" />
                            )
                          ) : (
                            <FunnelIcon className="h-3 w-3 inline ml-1" />
                          )}
                        </span>
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>

          <tbody {...getTableBodyProps()}>
            {page.length > 0 ? (
              page.map((row, i) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()} className={` border-b border-black/10 ${i % 2 === 1 && 'bg-black/5'}`}>
                    {row.cells.map((cell, index) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className={`${cell.column.class ? cell.column.class : ''}  py-2 px-2 text-black/50 ${index === row.cells.length - 1 ? 'text-right pr-4' : ''} ${index === 0 ? 'pl-4' : ''}`}
                        >
                          {cell.render('Cell')}
                        </td>
                      );
                    })}
                  </tr>
                );
              })
            ) : (
              <tr className="hover">
                <td colSpan={10000} className="text-center py-4 border-b border-black/10">
                  {t('table.no-data')}
                </td>
              </tr>
            )}
          </tbody>
        </table>
        {loading ? (
          <div className="absolute top-0 bottom-0 left-0 right-0 bg-black bg-opacity-5 rounded-md z-20 flex items-start justify-center">
            <div className="absolute p-3 bg-white w-36 shadow-md rounded-md text-center top-16">
              <div className="flex animate-pulse">
                <ClockIcon className="w-6 h-6 mr-1" /> <span> {t('general.loading')}</span>
              </div>
            </div>
          </div>
        ) : null}
        <div className="flex items-center py-2 px-4 text-xs">
          <div className="item-count hidden md:block">
            {loading ? (
              // Use our custom loading state to show a loading indicator
              <> {t('general.loading')}</>
            ) : (
              <>
                {totalRow > 0 && (
                  <>
                    {t('table.showing')} {pageIndex * pageSize + 1} {t('table.to')} {(pageIndex + 1) * pageSize < totalRow ? (pageIndex + 1) * pageSize : totalRow} {t('table.of')} {totalRow} {t('table.results')}
                  </>
                )}
              </>
            )}
          </div>
          <div className="pagination ml-auto">
            <button className={`p-1 w-6 rounded bg-primary text-white ${!canPreviousPage && 'opacity-50'}`} onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
              {'<<'}
            </button>{' '}
            <button className={`p-1 w-6 rounded bg-primary text-white ${!canPreviousPage && 'opacity-50'}`} onClick={() => previousPage()} disabled={!canPreviousPage}>
              {'<'}
            </button>{' '}
            <button className={`p-1 w-6  rounded bg-primary text-white ${!canNextPage && 'opacity-50'}`} onClick={() => nextPage()} disabled={!canNextPage}>
              {'>'}
            </button>{' '}
            <button className={`p-1 w-6  rounded bg-primary text-white ${!canNextPage && 'opacity-50'}`} onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
              {'>>'}
            </button>{' '}
          </div>
          <span className="ml-2 hidden md:block">
            {t('table.page')}{' '}
            <strong>
              {pageIndex + 1} {t('table.of')} {pageOptions.length}
            </strong>{' '}
            {t('table.total')} <strong>{preGlobalFilteredRows.length} </strong>
          </span>
          <span className="hidden md:block">
            &nbsp; | {t('table.go-to-page')}:{' '}
            <input
              type="number"
              defaultValue={pageIndex + 1}
              onChange={(e) => {
                const page = e.target.value ? Number(e.target.value) - 1 : 0;
                gotoPage(page);
              }}
              className="border border-black/10 rounded w-12 py-1 pl-2 outline-none"
            />
          </span>{' '}
          <select
            value={pageSize}
            onChange={(e) => {
              setPageSize(Number(e.target.value));
            }}
            className="border border-black/10 rounded bg-white  py-1 px-2 ml-2"
          >
            {[25, 50, 100].map((pageSize) => (
              <option key={pageSize} value={pageSize}>
                {t('table.show')} {pageSize}
              </option>
            ))}
          </select>
        </div>
      </div>
    </>
  );
}

export default TablePagination;
