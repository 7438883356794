import axios from 'axios';
import { api } from '../Settings';

const userData = JSON.parse(localStorage.getItem('_userData'));
const COACH_ID = userData && userData.data.coach_id;

export const submitQuiz = async (data = null, id = null) => {
  try {
    const request = await axios.post(api.submitQuiz, { data, id: id, coach_id: COACH_ID }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request;
    return response;
  } catch (error) {
    console.log(`submitQuiz error: ${error}`);
    return false;
  }
};
