import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import { useNavigate, Navigate } from 'react-router-dom';
import ResetPasswordForm from './../components/ResetPasswordForm';
import placeholder from './../assets/login.png';

const userData = JSON.parse(localStorage.getItem('_userData'));
const ASSCESS_TOKEN = userData && userData.data.token.access_token;

function withParams(Component) {
  return (props) => <Component {...props} navigation={useNavigate()} />;
}

class ResetPassword extends Component {
  constructor(props) {
    super(props);

    this.state = {
      componentData: [],
    };
  }

  /*  componentDidMount () {
     
      if(ASSCESS_TOKEN){
        console.log(ASSCESS_TOKEN);
      
      }

    } */

  render() {
    if (ASSCESS_TOKEN) {
      return <Navigate to="/" />;
    }

    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title> SportUnleash - Reset Password </title>
          </Helmet>
          <div className="h-screen w-full flex bg-white">
            <div className="hidden lg:w-1/2 bg-dark lg:flex items-center justify-center">
              <img className="logo mx-auto mb-6" width="560" height="auto" src={placeholder} />
            </div>
            <div className="w-full lg:w-1/2 flex items-center justify-center">
              <ResetPasswordForm />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withParams(ResetPassword);
