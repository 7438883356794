import axios from 'axios';
import { api } from '../Settings';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
const SCHOOL_ID = userData && userData.data.school_id;

export const getStudentDataTable = async (q = null) => {
  try {
    const cols = {};
    let order = null;
    let sort = null;
    let search = null;

    if (q.search) {
      search = q.search;
    }

    if (q.order.length && q.order[0].id) {
      order = q.order.length && q.order[0].id && q.order[0].desc ? 'desc' : 'asc';
      sort = q.order.length && q.order[0].id ? q.order[0].id : null;
    }

    q?.filters?.map((filter, i) => {
      if (filter.value) {
        cols[filter.key] = filter.value;
      }
    });

    const request = await axios(api.getStudents, {
      params: {
        page: q.page,
        per_page: q.per_page,
        sort: sort,
        sort_by: order,
        cols: cols,
        search: search,
        school_id: SCHOOL_ID,
        user_type: USER_TYPE,
      },
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request.data.data;
    return response;
  } catch (error) {
    console.log(`getStudentDataTable error: ${error}`);
    return false;
  }
};
