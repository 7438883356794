function setToken(type, res) {
  //  console.log("set token new serverssss");
  // console.log(type, res);
  if (type && res) {
    // const token = res.headers["cache-control"];
    // const token = res.headers["X-Authorization-Component"];
    const token = res.headers['x-authorization-component'];
    // console.warn('save up', type, token)
    //   console.warn('save sm', type, token)
    // localStorage.setItem(`_dash_token_${type}`,  token);
    // console.log(type, token)

    if (!token) return;

    const tokenData = localStorage.getItem(`_dash_tokens_`);

    if (!tokenData) {
      const obj = {};
      obj[type] = token;
      localStorage.setItem(`_dash_tokens_`, JSON.stringify(obj));
      // console.log(obj);
    } else {
      const obj = JSON.parse(tokenData);

      /*  if(obj[type]){ */
      obj[type] = token;
      /*  }else{
            } */

      localStorage.setItem(`_dash_tokens_`, JSON.stringify(obj));
      // console.log( JSON.stringify(obj));

      //console.log("update token")
    }
  }
}
function getToken(type) {
  //console.log(localStorage.getItem(`_dash_token_${type}`), type)

  const currTokens = localStorage.getItem(`_dash_tokens_`);
  if (currTokens && type) {
    const obj = JSON.parse(currTokens);

    //   console.log(JSON.parse(currTokens), obj[type]);

    if (type === 'multi_modules') {
      return obj;
    } else if (obj[type]) {
      // console.error(obj[type]);
      return obj[type];
    }
    //  console.log(JSON.parse(currTokens));
  }

  //return type && localStorage.getItem(`_dash_token_${type}`)
}

module.exports = {
  setToken,
  getToken,
};
