import { useNavigate } from 'react-router-dom';

const Logout = () => {
  const navigate = useNavigate();

  localStorage.removeItem('_userData');
  navigate('/', { replace: true });
  setTimeout(function () {
    navigate(0);
  }, 10);

  return <></>;
};

export default Logout;
