import axios from 'axios';
import { api } from '../Settings';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
const SCHOOL_ID = userData && userData.data.school_id;

export const getTest = async (id) => {
  try {
    const request = await axios(api.getTest, {
      params: { test_id: id, school_id: SCHOOL_ID, user_type: USER_TYPE },
    }).catch((err) => {
      if (err.response && err.response.status === 401) {
        window.location.href = '/logout';
      }
    });

    const response = request.data;
    return response;
  } catch (error) {
    console.log(`getTest error: ${error}`);
    return false;
  }
};
