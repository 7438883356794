import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, Outlet } from 'react-router-dom';
import axios from 'axios';

import { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Loader from './components/Loader';
import ScrollToTop from './ScrollToTop';

/* IMPORT COMPORNENTS */
import Login from './pages/Login';
import Logout from './pages/Logout';
import ForgetPassword from './pages/ForgetPassword';
import NewPassword from './pages/NewPassword';
import ResetPassword from './pages/ResetPassword';

import Dashboard from './pages/Dashboard';
import ResourceBank from './pages/ResourceBank';
import TakeTest from './pages/TakeTest';

import { BaseComp } from './pages/baseComp';
import { Student, ToApprove, Test, Parent, Staff, SUS, Profile } from './pages';

let user = false;

/* END OF IMPORT COMPORNENTS */
const userData = JSON.parse(localStorage.getItem('_userData'));
const ASSCESS_TOKEN = userData && userData.data.token.access_token;
const REFRESH_TOKEN = userData && userData.data.token.refresh_token;
const USER_KEY = userData && userData.user_key;
const USER_TYPE = userData && userData.data.user_type;
const ELIGIBLE = userData && userData.data.eligibility;
const TEST_ACCESS = userData && USER_TYPE.toUpperCase() === 'COACH' && ELIGIBLE;
const IS_SUS = userData && userData.data.is_sus;

if (ASSCESS_TOKEN) {
  axios.defaults.headers.common['Authorization'] = `Bearer ${ASSCESS_TOKEN}`;
  axios.defaults.headers.common['User-Key'] = `${USER_KEY}`;
  axios.defaults.headers.common['User-Rt'] = `${REFRESH_TOKEN}`;
  // document.cookie = `at=${ASSCESS_TOKEN}`;
  user = true;
}
const ProtectedRoute = ({ user, redirectPath = '/login' }) => {
  /*  const userData =  JSON.parse(localStorage.getItem("_userData"));
  const ASSCESS_TOKEN = (userData) && userData.data.data.access_token; */

  if (!ASSCESS_TOKEN) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};
const ProtectedRouteByType = ({ userTypes, redirectPath = '/' }) => {
  /* ADMIN,COACH,SCHOOL,STAFF,PARENT */
  // const types = userTypes?.split(",");
  // console.log(userTypes.includes(USER_TYPE), "ssss", USER_TYPE, types);

  if (!userTypes.includes(USER_TYPE.toUpperCase())) {
    return <Navigate to={redirectPath} replace />;
  } else if (USER_TYPE.toUpperCase() === 'COACH' && !IS_SUS) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

const ProtectedResourceRoute = ({ userTypes, redirectPath = '/' }) => {
  /* ADMIN,COACH,SCHOOL,STAFF,PARENT */
  // const types = userTypes?.split(",");
  // console.log(userTypes.includes(USER_TYPE), "ssss", USER_TYPE, types);

  if (!userTypes.includes(USER_TYPE.toUpperCase())) {
    return <Navigate to={redirectPath} replace />;
  } /* else if(USER_TYPE.toUpperCase() !== "COACH"){

    return <Navigate to={redirectPath} replace />;

  } */

  return <Outlet />;
};

const ProtectedTestRoute = ({ redirectPath = '/' }) => {
  if (!TEST_ACCESS) {
    return <Navigate to={redirectPath} replace />;
  }

  return <Outlet />;
};

function App() {
  const { t, i18n } = useTranslation();

  return (
    <Router>
      <ScrollToTop />
      <Routes>
        <Route path="/login" element={<Login />} extend />
        <Route path="/forget-password" element={<ForgetPassword />} extend />
        <Route path="/new-password" element={<NewPassword />} extend />
        <Route path="/reset-password" element={<ResetPassword />} extend />

        <Route element={<ProtectedRoute user={user} />}>
          {/* <Route index element={<Dashboard />} /> */}
          <Route path="/" element={<BaseComp />}>
            <Route path="/" element={<Dashboard />} />
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'SCHOOL']} />}>
            <Route path="/staff-management" element={<BaseComp />}>
              <Route path="/staff-management" element={<Staff.StaffManagement />} />
              <Route path="/staff-management/create" element={<Staff.StaffCreate />} />
              <Route path="/staff-management/:id" element={<Staff.StaffDetail />} />
              <Route path="/staff-management/:id/edit" element={<Staff.StaffEdit />} />
            </Route>
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'SCHOOL', 'STAFF', 'PARENT']} />}>
            <Route path="/sus-management" element={<BaseComp />}>
              <Route path="/sus-management" element={<SUS.SUSManagement />} />
              <Route path="/sus-management/:id" element={<SUS.SUSDetail />} />
            </Route>
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'SCHOOL', 'STAFF']} />}>
            <Route path="/parent-management" element={<BaseComp />}>
              <Route path="/parent-management" element={<Parent.ParentManagement />} />
              <Route path="/parent-management/create" element={<Parent.ParentCreate />} />
              <Route path="/parent-management/:id" element={<Parent.ParentDetail />} />
              <Route path="/parent-management/:id/edit" element={<Parent.ParentEdit />} />
            </Route>
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'COACH', 'SCHOOL', 'STAFF', 'PARENT']} />}>
            <Route path="/test-management" element={<BaseComp />}>
              <Route path="/test-management" element={<Test.TestManagement />} />
              <Route path="/test-management/:id" element={<Test.TestDetail />} />
              <Route path="/test-management/:id/edit" element={<Test.TestEdit />} />
            </Route>
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'COACH', 'SCHOOL', 'STAFF']} />}>
            <Route path="/test-management" element={<BaseComp />}>
              <Route path="/test-management/create" element={<Test.TestCreate />} />
            </Route>
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'COACH']} />}>
            <Route path="/to-approve" element={<BaseComp />}>
              <Route path="/to-approve" element={<ToApprove.ToApprove />} />
              <Route path="/to-approve/:id" element={<ToApprove.ToApproveDetail />} />
            </Route>
          </Route>

          <Route element={<ProtectedRouteByType userTypes={['ADMIN', 'COACH', 'SCHOOL', 'STAFF', 'PARENT']} />}>
            <Route path="/student-management/" element={<BaseComp />}>
              <Route path="/student-management/" element={<Student.StudentManagement />} />
              <Route path="/student-management/create" element={<Student.StudentCreate />} />
              <Route path="/student-management/:id" element={<Student.StudentDetail />} />
              <Route path="/student-management/:id/edit" element={<Student.StudentEdit />} />
            </Route>
          </Route>

          <Route element={<ProtectedResourceRoute userTypes={['ADMIN', 'COACH', 'SCHOOL', 'STAFF']} />}>
            <Route path="/resource-bank" element={<ResourceBank />} />
          </Route>

          <Route path="/my-profile" element={<BaseComp />}>
            <Route path="/my-profile" element={<Profile.MyProfile />} />
            <Route path="/my-profile/edit" element={<Profile.EditProfile />} />
            <Route path="/my-profile/gallery" element={<Profile.EditGallery />} />
          </Route>

          <Route element={<ProtectedTestRoute />}>
            <Route path="/take-a-test" element={<TakeTest />} />
          </Route>

          <Route path="/logout" element={<Logout />} />
        </Route>
      </Routes>
    </Router>
  );
}

export default function WrappedApp() {
  return (
    <Suspense
      fallback={
        <span className="fixed top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2">
          <Loader />
        </span>
      }
    >
      <App />
      {/* <GoogleTranslate /> */}
    </Suspense>
  );
}
