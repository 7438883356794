import { FiChevronRight } from 'react-icons/fi';
import { Link, useLocation } from 'react-router-dom';
import useBreadcrumbs from 'use-react-router-breadcrumbs';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;

function Breadcrumbs() {
  const location = useLocation();

  const routes = [
    { path: '/', breadcrumb: 'Dashboard' },
    { path: '/su-admin', breadcrumb: 'Country Admins' },
    {
      path: '/su-admin/:id',
      breadcrumb: location.state?.first_name ? location.state?.first_name + ' ' + location.state?.last_name : 'Admin Details',
    },
    { path: '/su-admin/create', breadcrumb: 'Create' },

    { path: '/school-management', breadcrumb: 'Schools' },
    { path: '/school-management/:id', breadcrumb: location.state?.school_name },
    { path: '/school-management/create', breadcrumb: 'Add New School' },

    { path: '/sus-management', breadcrumb: 'SportUnleash Specialist' },
    {
      path: '/sus-management/:id',
      breadcrumb: location.state?.first_name ? location.state?.first_name + ' ' + location.state?.last_name : 'SUS Details',
    },
    { path: '/sus-management/create', breadcrumb: 'Add a Specialist' },

    { path: '/parent-management', breadcrumb: 'Parents' },
    { path: '/parent-management/:id', breadcrumb: location.state?.fathers_name },
    { path: '/parent-management/create', breadcrumb: 'Add a Parent' },

    { path: '/staff-management', breadcrumb: 'Staff' },
    { path: '/staff-management/:id', breadcrumb: location.state?.name },
    { path: '/staff-management/create', breadcrumb: 'Add a Staff Member' },

    { path: '/test-management', breadcrumb: 'Tests' },
    { path: '/test-management/:id', breadcrumb: 'Test Details' },
    { path: '/test-management/create', breadcrumb: 'Add a Test' },

    { path: '/my-profile/gallery', breadcrumb: 'Manage Assets' },

    { path: '/to-approve', breadcrumb: 'To Approve' },
    { path: '/to-approve/:id', breadcrumb: 'Test Details' },

    { path: '/student-management', breadcrumb: USER_TYPE.toUpperCase() === 'PARENT' ? 'Childs' : 'Students' },
    {
      path: '/student-management/:id',
      breadcrumb: location.state?.first_name ? location.state?.first_name + ' ' + location.state?.last_name : USER_TYPE.toUpperCase() === 'PARENT' ? 'Child Details' : 'Student Details',
    },
    {
      path: '/student-management/create',
      breadcrumb: USER_TYPE.toUpperCase() === 'PARENT' ? 'Add a Child' : 'Add a Student',
    },
  ];

  const breadcrumbs = useBreadcrumbs(routes);

  return (
    <nav className="flex flex-wrap items-start mb-4 text-sm md:text-base">
      {breadcrumbs.map(({ match, breadcrumb }) => (
        <Link key={breadcrumb.key} to={breadcrumb.key} className={`flex items-start ${match.pathname === location.pathname ? 'text-body' : 'text-body/50 hover:text-primary'}`}>
          {breadcrumb}
          {match.pathname !== location.pathname && (
            <span className="px-1 md:mt-0.5 text-2xl text-body/20">
              <FiChevronRight />
            </span>
          )}
        </Link>
      ))}
    </nav>
  );
}

export default Breadcrumbs;
