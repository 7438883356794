import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { getStudentDataTable } from '../services/getStudents';
import TablePagination from './TablePagination';
import { BsEyeFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { MdContentCopy, MdOutlineInsertDriveFile, MdOutlineNoteAdd, MdOutlineNoteAlt, MdOutlineSchool } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as dayjs from 'dayjs';
import { FaWaveSquare } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
const PARENT_ID = userData && userData.data.parent_id;

//const baseURL = "https://sportunleash.preview.cms.smart360.ebeyondsonline.com"
const baseURL = 'https://www.sportunleash.com';

function StudentDataTable() {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);
  const { t, i18n } = useTranslation();

  const fetchData = useCallback(async (pageSize, pageIndex, search, order, filters) => {
    setLoading(true);
    const queryOptions = {
      page: pageIndex + 1,
      per_page: pageSize,
      search: search,
      order: order,
      filters: filters,
    };
    const items = await getStudentDataTable(queryOptions);
    const data = items && items.data ? items.data : [];

    // console.log("call to get data");
    setData(data);
    setPageCount(Math.ceil(items.total / items.per_page));
    setTotalRow(items.total);
    setLoading(false);
  }, []);

  const colData = [
    {
      Header: t('table.columns.student_name'),
      accessor: 'first_name',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            <span className="text-body block font-semibold mb-0.5 ">
              {row.original.first_name} {row.original.last_name}
            </span>
            <span
              className="hidden md:inline-flex  text-body italic bg-primary/10 rounded-xl px-3 py-1 text-xsitems-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20"
              onClick={() => {
                navigator.clipboard.writeText(row.original.child_id);
                toast.info('Student ID Copied to Clipboard', {
                  position: 'bottom-center',
                  hideProgressBar: true,
                  theme: 'dark',
                  autoClose: 1000,
                  theme: 'colored',
                });
              }}
            >
              {row.original.child_id}
              <MdContentCopy className="text-primary text-sm  ml-1" />
            </span>
          </>
        );
      },
    },
    {
      Header: t('table.columns.added_date'),
      accessor: 'created_at',
      class: 'hidden md:table-cell',
      Cell: ({ value }) => {
        return dayjs(value).format('DD MMM YYYY');
      },
    },
    {
      Header: t('table.columns.date_of_birth'),
      accessor: 'date_of_birth',
      class: 'hidden md:table-cell',
      Cell: ({ value }) => {
        return dayjs(value).format('DD MMM YYYY');
      },
    },
    /*  {
      Header: "Mother's Name",
      accessor: "mothers_name",
      class:"hidden md:table-cell",
    }, */
    /*  {
      Header: "Father's Name",
      accessor: "fathers_name",
      class:"hidden md:table-cell",
    }, */
    {
      Header: t('table.columns.test_status'),
      accessor: 'status',
      class: 'hidden md:table-cell',
      Cell: ({ value }) => {
        return <>{value === 'completed' ? <span className="text-green-500">{t('general.completed')}</span> : <span className="text-orange-500">{t('general.pending')}</span>}</>;
      },
    },
  ];

  if (['ADMIN', 'COACH', 'SCHOOL', 'STAFF'].includes(USER_TYPE.toUpperCase())) {
    colData.push({
      Header: '',
      accessor: 'test',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.status === 'completed' ? (
              <span
                className="text-primary italic bg-primary/10 rounded-xl px-3 py-1 text-sm inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20 whitespace-nowrap"
                onClick={() => {
                  navigator.clipboard.writeText(row.original.child_id);
                }}
              >
                <MdOutlineInsertDriveFile className="text-primary text-base  mr-1" />
                {t('general.view-test')}
              </span>
            ) : (
              <Link
                to={'/test-management/create'}
                state={row.original.child_id}
                className="text-primary italic bg-primary/10 rounded-xl px-3 py-1 text-sm inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20  whitespace-nowrap"
              >
                <MdOutlineNoteAdd className="text-primary text-base  mr-1" />
                {t('general.add-test')}
              </Link>
            )}
          </>
        );
      },
    });
  }

  if (['PARENT'].includes(USER_TYPE.toUpperCase())) {
    colData.push({
      Header: t('table.columns.payment'),
      accessor: 'test',
      disableSortBy: true,
      Cell: ({ row }) => {
        return (
          <>
            {row.original.payment !== true ? (
              <a
                href={`${baseURL}/paylink/p/${row.original.child_id}/`}
                target="_blank"
                className="text-primary italic bg-primary/10 rounded-xl px-3 py-1 text-sm inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20 whitespace-nowrap"
                title="Pay Now"
              >
                {t('general.pay-now')}
              </a>
            ) : (
              <span className="text-green-500">{t('general.paid')}</span>
            )}
          </>
        );
      },
    });
  }

  colData.push({
    Header: '',
    accessor: 'actions',
    disableSortBy: true,
    Cell: ({ row }) => {
      //query: {paramobj: JSON.stringify(row)}
      // console.warn(JSON.stringify(row));
      //  console.warn(row);

      return (
        <div className="flex gap-2 text-xl items-center justify-end text-icon">
          {/*  <div className="flex justify-center">
                      <div>
                      <input
                          className="mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[rgba(0,0,0,0.25)] outline-none before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-icon after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary/20 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary/30 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                          type="checkbox"
                          role="switch"
                          />
                      </div>
                  </div> */}
          {/*   <button className="btn btn-xs btn-info">
                      <MdDelete className='cursor-pointer'/>
                  </button> */}
          <Link to={`/student-management/${row.original.child_id}`} state={row.original} className="btn btn-xs btn-error hover:text-primary">
            <BsEyeFill className="cursor-pointer" />
          </Link>
        </div>
      );
    },
  });

  const columns = useMemo(() => colData, []);

  const filters = useMemo(
    () => [
      {
        label: 'Status',
        key: 'status',
        value: null,
        selected: null,
        icon: <FaWaveSquare />,
        options: [
          {
            id: 'verified',
            name: 'Verified',
          },
          {
            id: 'unverified',
            name: 'Unverified',
          },
        ],
      },
    ],
    [],
  );

  return (
    <section className="relative">
      <TablePagination filters={filters} columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} totalRow={totalRow} />
      <ToastContainer />
    </section>
  );
}

export default StudentDataTable;
