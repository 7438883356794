import React, { Component } from 'react';
import { FaSchool, FaUser, FaRegHandshake, FaChild, FaUserTie } from 'react-icons/fa';
import { MdOutlineDirectionsRun, MdDashboard, MdSchool, MdOutlineAccountCircle, MdOutlineClose, MdMenu, MdOutlineSettings, MdPendingActions, MdOutlinePermMedia } from 'react-icons/md';
import { BiImages, BiLineChart } from 'react-icons/bi';
import { BsNewspaper, BsCalendarEvent } from 'react-icons/bs';
import { GiWorld } from 'react-icons/gi';
import { AiOutlineLogout, AiOutlineHome } from 'react-icons/ai';
import { NavLink } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

import logo from './../assets/logo.png';
import { RiParentFill } from 'react-icons/ri';

const userData = JSON.parse(localStorage.getItem('_userData'));
const ASSCESS_TOKEN = userData && userData.data.token.access_token;
const USER_TYPE = userData && userData.data.user_type;
let firstName = userData && userData.data.user_details && userData.data.user_details.first_name ? `${userData.data.user_details.first_name}` : '';
const lastName = userData && userData.data.user_details && userData.data.user_details.last_name ? `${userData.data.user_details.last_name}` : '';
const userEmail = userData && userData.data.user_details && userData.data.user_details.username ? `${userData.data.user_details.username}` : '';
const userImage =
  userData && userData.data.user_details && userData.data.base_account_url ? `${userData.data.base_account_url}/getUserAvatar.php?userid=${userData.data.details.user_id}&access_token=${ASSCESS_TOKEN}` : logo;
const ELIGIBLE = userData && userData.data.eligibility;
const TEST_ACCESS = userData && USER_TYPE.toUpperCase() === 'COACH' && ELIGIBLE;
const IS_SUS = userData && userData.data.is_sus;

if (USER_TYPE) {
  firstName = userData && userData.data.user_details && userData.data.user_details[USER_TYPE.toLowerCase() + '_name'] ? `${userData.data.user_details[USER_TYPE.toLowerCase() + '_name']}` : '';
}

const navigation = [
  {
    label: 'Dashboard',
    code: 'dashboard',
    url: '/',
    icon: <MdDashboard />,
  },
  /* {
        url: "/su-admin",
        label: "SU Admin",
        icon: <FaUser />,
        userTypes:["ADMIN"]
    }, */
  {
    url: '/school-management',
    code: 'school-management',
    label: 'Schools',
    icon: <FaSchool />,
    userTypes: ['ADMIN'],
  },
  {
    url: '/staff-management',
    code: 'staff-management',
    label: 'Staff',
    icon: <FaUserTie />,
    userTypes: ['ADMIN', 'SCHOOL'],
  },
  {
    url: '/sus-management',
    code: 'sus-management',
    label: 'SportUnleash Specialists',
    icon: <MdOutlineDirectionsRun />,
    userTypes: ['ADMIN', 'SCHOOL', 'PARENT'],
  },
  {
    url: '/parent-management',
    code: 'parent-management',
    label: 'Parents',
    icon: <RiParentFill />,
    userTypes: ['ADMIN', 'SCHOOL', 'STAFF'],
  },
  {
    url: '/student-management',
    code: USER_TYPE?.toUpperCase() === 'PARENT' ? 'child-management' : 'student-management',
    label: 'Students',
    icon: <FaChild />,
    userTypes: ['ADMIN', 'COACH', 'SCHOOL', 'STAFF', 'PARENT'],
  },
  {
    url: '/test-management',
    code: 'test-management',
    label: 'Tests',
    icon: <BiLineChart />,
    userTypes: ['ADMIN', 'COACH', 'SCHOOL', 'STAFF', 'PARENT'],
  },
  {
    url: '/to-approve',
    code: 'to-approve',
    label: 'To Approve',
    icon: <MdPendingActions />,
    userTypes: ['ADMIN', 'COACH'],
  },
  {
    url: '/my-profile',
    code: 'my-profile',
    label: 'My Profile',
    icon: <MdOutlineAccountCircle />,
  },
  {
    url: '/resource-bank',
    code: 'resource-bank',
    label: 'Training Resources',
    icon: <MdOutlinePermMedia />,
    userTypes: ['ADMIN', 'COACH', 'SCHOOL', 'STAFF'],
  },
  {
    url: '/settings',
    code: 'settings',
    label: 'Settings',
    icon: <MdOutlineSettings />,
    userTypes: ['ADMIN'],
  },
  {
    url: '/logout',
    code: 'logout',
    label: 'Log Out',
    type: 'action',
    icon: <AiOutlineLogout />,
  },
];

class Drawer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: true,
    };

    this.toggleDrawer = this.toggleDrawer.bind(this);

    if (window.innerWidth < 1280) {
      this.state.show = false;
    }

    // console.log(this.state);
  }

  /*  static getDerivedStateFromProps(props, state) {
        return {show: props.show };
    } */

  toggleDrawer() {
    let show = !this.state.show;
    // this.props.drawerCallback({ show: show });
    //console.log(show);
    this.setState({ show: show });
  }

  render() {
    const { t } = this.props;
    return (
      <>
        <div
          id="drawer-navigation"
          className={`fixed 2xl:relative z-40 min-h-screen overflow-y-auto bg-bg w-80  transition-all left-0 top-0 border border-r border-black/10  ${
            this.state.show ? 'min-w-[320px]' : 'hidden md:block min-w-[84px] w-[84px]'
          }`}
          tabIndex="-1"
          aria-labelledby="drawer-navigation-label"
        >
          <div className={`fixed max-h-screen overflow-auto top-0 left-0 pt-4 ${this.state.show ? 'min-w-[320px]' : 'min-w-[84px] w-[84px]'}`}>
            {this.state.show && (
              <NavLink to="/" className="mx-2  w-[175px] inline-block">
                <img className="logo w-full" src={logo} />
              </NavLink>
            )}

            <button
              onClick={this.toggleDrawer}
              type="button"
              data-drawer-hide="drawer-navigation"
              aria-controls="drawer-navigation"
              className={`text-primary bg-transparent hover:bg-gray-200 rounded-lg text-sm p-1.5 inline-flex items-center  ${!this.state.show ? 'absolute top-2.5 left-5' : 'absolute top-2.5 right-2.5'}`}
            >
              {this.state.show ? <MdOutlineClose className="text-2xl" /> : <MdMenu className="text-3xl" />}
            </button>

            <div className={`flex items-center px-4 ${!this.state.show ? 'mt-16 mb-6' : ' mt-8  mb-4 '}`}>
              <div className="nav-logo min-w-[48px] w-[48px] h-[48px] rounded-full bg-no-repeat bg-contain border border-primary bg-center" style={{ backgroundImage: `url("${userImage}")` }}></div>
              {this.state.show && (
                <div className=" ml-4 ">
                  <h5 className="text-base font-semibold text-body whitespace-nowrap max-w-[200px] text-ellipsis overflow-hidden">
                    {firstName} {lastName}
                  </h5>
                  <span className="text-primary capitalize font-semibold text-sm block">{USER_TYPE}</span>
                  <a className="text-sm text-body">{userEmail}</a>
                </div>
              )}
            </div>

            <div className="p-4 overflow-y-auto max-h-[calc(100vh-210px)]">
              <ul className="space-y-2">
                {navigation.map((item, index) => {
                  return (
                    (!item?.userTypes || item?.userTypes?.includes(USER_TYPE.toUpperCase())) && (
                      <React.Fragment key={index}>
                        {!item?.userTypes || USER_TYPE.toUpperCase() !== 'COACH' ? (
                          <NavLink
                            to={item.url}
                            key={index}
                            className={({ isActive }) =>
                              isActive
                                ? 'text-primary  flex items-center p-2 text-base font-normal rounded-lg group bg-primary/20'
                                : 'flex items-center p-2 text-base font-normal text-ash rounded-lg hover:bg-gray-100 group'
                            }
                          >
                            <div className={`flex justify-center  ${!this.state.show ? 'w-full' : ''}`}>
                              <div className={`menu-icon text-2xl`}>
                                <div className="w-6 h-6 text-primary transition duration-75">{item.icon}</div>
                              </div>
                            </div>
                            {this.state.show && <div className="ml-3">{t('navigation.' + [item.code])}</div>}
                          </NavLink>
                        ) : IS_SUS ? (
                          <NavLink
                            to={item.url}
                            key={index}
                            className={({ isActive }) =>
                              isActive
                                ? 'text-primary  flex items-center p-2 text-base font-normal rounded-lg group bg-primary/20'
                                : 'flex items-center p-2 text-base font-normal text-ash rounded-lg hover:bg-gray-100 group'
                            }
                          >
                            <div className={`flex justify-center  ${!this.state.show ? 'w-full' : ''}`}>
                              <div className={`menu-icon text-2xl`}>
                                <div className="w-6 h-6 text-primary transition duration-75">{item.icon}</div>
                              </div>
                            </div>
                            {this.state.show && <div className="ml-3">{t('navigation.' + [item.code])}</div>}
                          </NavLink>
                        ) : (
                          item.code === 'resource-bank' && (
                            <NavLink
                              to={item.url}
                              key={index}
                              className={({ isActive }) =>
                                isActive
                                  ? 'text-primary  flex items-center p-2 text-base font-normal rounded-lg group bg-primary/20'
                                  : 'flex items-center p-2 text-base font-normal text-ash rounded-lg hover:bg-gray-100 group'
                              }
                            >
                              <div className={`flex justify-center  ${!this.state.show ? 'w-full' : ''}`}>
                                <div className={`menu-icon text-2xl`}>
                                  <div className="w-6 h-6 text-primary transition duration-75">{item.icon}</div>
                                </div>
                              </div>
                              {this.state.show && <div className="ml-3">{t('navigation.' + [item.code])}</div>}
                            </NavLink>
                          )
                        )}
                      </React.Fragment>
                    )
                  );
                })}
              </ul>
            </div>
          </div>
        </div>

        <div className="flex md:hidden items-center relative justify-center py-4">
          <button
            onClick={this.toggleDrawer}
            type="button"
            data-drawer-hide="drawer-navigation"
            aria-controls="drawer-navigation"
            className={`text-primary bg-transparent hover:bg-gray-200 rounded-lg text-sm p-1.5 inline-flex items-center absolute left-0 top-1/2 -translate-y-1/2`}
          >
            {this.state.show ? <MdOutlineClose className="text-2xl" /> : <MdMenu className="text-3xl" />}
          </button>

          <NavLink to="/" className="w-[105px] inline-block">
            <img className="logo w-full" src={logo} />
          </NavLink>
        </div>
      </>
    );
  }
}

export default withTranslation()(Drawer);
