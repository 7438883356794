import React, { useState } from 'react';
import ReactFlagsSelect from 'react-flags-select';
import { BsCaretDownFill } from 'react-icons/bs';
import { MdContentCopy, MdEdit } from 'react-icons/md';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as dayjs from 'dayjs';
import CreatableSelect from 'react-select/creatable';

export default function Field(props) {
  const [selected, setSelected] = useState('');
  const fieldConfig = props.fieldConfig;
  const [value, setValue] = useState();
  const [today, setToday] = useState(dayjs().format('YYYY-MM-DD'));

  let element = null;
  let classes = ['field'];
  if (fieldConfig.touched && !fieldConfig.valid) {
    classes.push('invalid');
  }

  React.useEffect(() => {
    if (fieldConfig.name === 'country') {
      const countryObj = fieldConfig.options?.filter((country) => country.id === fieldConfig.value);

      if (countryObj && countryObj.length === 1) {
        setSelected(countryObj[0].alpha_2.toUpperCase());
      }
    }
  }, []);

  React.useEffect(() => {
    if (fieldConfig.name === 'country') {
      if (fieldConfig.value === '') {
        setSelected('');
      } else {
        /*  console.log(fieldConfig);
                 console.log(fieldConfig.options); */
        const countryObj = fieldConfig.options?.filter((country) => country.id === fieldConfig.value);

        // console.log(countryObj, fieldConfig.value);
        if (countryObj && countryObj.length === 1) {
          setSelected(countryObj[0].alpha_2.toUpperCase());
        }
      }
    }
  }, [fieldConfig.value]);

  const previewFile = (e) => {
    var preview = document.querySelector('.photo-thumb');
    var file = document.querySelector('.photo-file').files[0];
    var reader = new FileReader();

    reader.onloadend = function () {
      let thumb = reader.result ? reader.result : fieldConfig.value;
      preview.style.backgroundImage = 'url(' + thumb + ')';
    };

    if (file) {
      reader.readAsDataURL(file);
    } else {
      preview.src = '';
    }

    props.changed(e);
  };

  switch (fieldConfig.type) {
    case 'email':
    case 'text':
    case 'number':
    case 'password':
    case 'hidden':
      element = (
        <input
          type={fieldConfig.type}
          name={fieldConfig.id}
          value={fieldConfig.value}
          className={classes.join(' ') + ' bg-primary/5 border-black/10   appearance-none border  rounded-lg w-full py-3 px-4 h-12 text-body leading-tight focus:outline-none focus:border-primary'}
          placeholder={`${fieldConfig.placeholder ? fieldConfig.placeholder : `Please enter your ${fieldConfig.label?.toLowerCase()}`}`}
          onBlur={props.blured}
          minLength={fieldConfig.validation.minLength}
          onChange={props.changed}
          maxLength={fieldConfig.validation.maxLength}
        />
      );
      break;
    case 'date':
      element = (
        <input
          type={fieldConfig.type}
          name={fieldConfig.id}
          value={fieldConfig.value}
          className={classes.join(' ') + ' bg-primary/5 border-black/10   appearance-none border  rounded-lg w-full py-3 px-4 h-12 text-body leading-tight focus:outline-none focus:border-primary'}
          placeholder={`${fieldConfig.placeholder ? fieldConfig.placeholder : `Please enter your ${fieldConfig.label?.toLowerCase()}`}`}
          onBlur={props.blured}
          onChange={props.changed}
          max={today}
        />
      );
      break;
    case 'ref':
      element = (
        <div className="relative">
          <input
            type="text"
            readOnly
            name={fieldConfig.id}
            value={fieldConfig.value}
            className={classes.join(' ') + ' bg-primary/5 border-black/10   appearance-none border  rounded-lg w-full py-3 px-4 h-12 text-body leading-tight focus:outline-none focus:border-primary'}
            placeholder={`${fieldConfig.placeholder ? fieldConfig.placeholder : `Please enter your ${fieldConfig.label?.toLowerCase()}`}`}
            onBlur={props.blured}
            minLength={fieldConfig.validation.minLength}
            onChange={props.changed}
            maxLength={fieldConfig.validation.maxLength}
          />
          <span
            className="absolute right-4 top-1/2 -translate-y-1/2 items-center cursor-pointer"
            title="Copy to clipboard"
            onClick={() => {
              navigator.clipboard.writeText(fieldConfig.value);
              toast.info('Ref Data Copied to Clipboard', {
                position: 'bottom-center',
                hideProgressBar: true,
                theme: 'dark',
                autoClose: 1000,
                theme: 'colored',
              });
            }}
          >
            <MdContentCopy className="text-primary text-lg  ml-1" />
          </span>
          <ToastContainer />
        </div>
      );
      break;
    case 'file':
      element = (
        <input
          type={fieldConfig.type}
          name={fieldConfig.id}
          /*  value={fieldConfig.value} */
          accept="image/*"
          className={classes.join(' ') + ' bg-primary/5 border-black/10   appearance-none border  rounded-lg w-full py-3 px-4 h-12 text-body leading-tight focus:outline-none focus:border-primary'}
          placeholder={`Please enter your ${fieldConfig.label?.toLowerCase()}`}
          onBlur={props.blured}
          minLength={fieldConfig.validation.minLength}
          onChange={props.changed}
          maxLength={fieldConfig.validation.maxLength}
        />
      );
      break;
    case 'photo':
      element = (
        <div className="avatar-upload relative flex items-center w-[250px]" title={fieldConfig.label}>
          <div className="avatar-preview relative w-full h-[250px] rounded-full border-4 border-white shadow">
            <div className="photo-thumb w-full h-full bg-primary/10 bg-no-repeat bg-cover bg-center mr-4 rounded-full" style={{ backgroundImage: `url("${fieldConfig.value}")` }}>
              {' '}
            </div>
          </div>
          <div className="avatar-edit absolute top-4 right-4 z-10">
            <input
              type={'file'}
              id={fieldConfig.name}
              name={fieldConfig.name}
              accept="image/*"
              className={'photo-file hidden'}
              placeholder={`Please enter your ${fieldConfig.label?.toLowerCase()}`}
              onChange={previewFile}
              onBlur={props.blured}
              minLength={fieldConfig.validation.minLength}
              maxLength={fieldConfig.validation.maxLength}
            />
            <label className={`flex items-center justify-center w-10 h-10 rounded-full bg-white cursor-pointer transition-all shadow hover:bg-white/80`} htmlFor={fieldConfig.name}>
              <MdEdit className="text-body/50 text-xl" />
            </label>
          </div>
        </div>
      );
      break;
    case 'textarea':
      element = (
        <textarea
          value={fieldConfig.value}
          className={classes.join(' ') + ' bg-primary/5 border-black/10  appearance-none border rounded w-full  py-3 px-4 h-24 text-body leading-tight focus:outline-none focus:shadow-outline'}
          placeholder={fieldConfig.placeholder}
          onBlur={props.blured}
          minLength={fieldConfig.validation.minLength}
          onChange={props.changed}
          maxLength={fieldConfig.validation.maxLength}
        />
      );
      break;
    case 'select':
      element = (
        <div className="relative">
          <select
            value={fieldConfig.value}
            className={classes.join(' ') + ' bg-primary/5 border-black/10  appearance-none border rounded-lg w-full py-3 px-4  h-12 text-body leading-tight focus:outline-none focus:shadow-outline focus:border-primary'}
            onBlur={props.blured}
            onChange={props.changed}
          >
            <option value="">{fieldConfig.placeholder}</option>
            {fieldConfig.options &&
              fieldConfig.options.length > 0 &&
              fieldConfig.options.map((option) => (
                <option key={option.id} value={option.id}>
                  {option.name}
                </option>
              ))}
          </select>
          <BsCaretDownFill className="absolute right-4 top-1/2 -translate-y-1/2 text-body/60 w-3 h-3" />
        </div>
      );
      break;
    case 'tel':
      element = (
        <PhoneInput
          containerClass={'w-full relative bg-primary/5'}
          inputClass={'border-black/10 appearance-none border  rounded-lg w-full py-3 px-4  h-12 text-body leading-tight focus:outline-none focus:border-primary'}
          country={'us'}
          value={fieldConfig.value}
          onChange={(phone) => {
            fieldConfig.value = phone;
            props.changed();
          }}
        />
      );
      break;
    case 'country':
      const validCountries = fieldConfig.options?.map((a) => a.alpha_2.toUpperCase());

      element = (
        <ReactFlagsSelect
          countries={validCountries}
          className={'country-select'}
          selected={selected}
          searchable
          onSelect={(code) => {
            setSelected(code);
            const c = fieldConfig?.options?.filter((item) => item.alpha_2.toUpperCase() === code);
            if (c && c.length) {
              fieldConfig.value = c[0].id;
            }
            props.changed();
          }}
        />
      );
      break;
    case 'customSelect':
      //  const validCountries = fieldConfig.options?.map(a => a.alpha_2.toUpperCase());

      element = (
        <CreatableSelect
          isClearable
          className="cs-select"
          classNamePrefix="cs-select"
          // isDisabled={isLoading}
          //  isLoading={isLoading}
          onChange={(newValue) => {
            setValue(newValue);
            //console.log(newValue);
            fieldConfig.value = newValue.value;
            props.changed();
          }}
          //onCreateOption={handleCreate}
          options={fieldConfig.options}
          value={value}
        />
      );
      break;
    case 'radio':
      element = (
        <div className="flex items-center h-12">
          {fieldConfig.options &&
            fieldConfig.options.length > 0 &&
            fieldConfig.options.map((option) => (
              <React.Fragment key={option.id}>
                <div className="mr-4 block pl-[1.5rem]">
                  <input
                    className="relative float-left mt-0.5 mr-1 -ml-[1.5rem] h-5 w-5 appearance-none rounded-full border-2 border-solid border-primary before:pointer-events-none before:absolute before:h-4 before:w-4 before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] after:absolute after:z-[1] after:block after:h-4 after:w-4 after:rounded-full after:content-[''] checked:border-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:left-1/2 checked:after:top-1/2 checked:after:h-[0.625rem] checked:after:w-[0.625rem] checked:after:rounded-full checked:after:border-primary checked:after:bg-primary checked:after:content-[''] checked:after:[transform:translate(-50%,-50%)] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:border-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                    type="radio"
                    onBlur={props.blured}
                    onChange={props.changed}
                    name={fieldConfig.name}
                    checked={fieldConfig.value === option.id ? 'checked' : ''}
                    id={option.id}
                    value={option.id}
                  />
                  <label className="block pl-1 text-body/80 hover:cursor-pointer" htmlFor={option.id}>
                    {option.name}
                  </label>
                </div>
              </React.Fragment>
            ))}
        </div>
      );
      break;
    case 'checkbox':
      element = (
        <div className="mt-4 mb-2 block min-h-[1.5rem]">
          <input
            className="relative float-left mt-[0.15rem] mr-[6px] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-primary outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:content-[''] checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
            type="checkbox"
            name={fieldConfig.name}
            id={fieldConfig.name}
            defaultChecked={fieldConfig.value ? 'checked' : ''}
            onChange={props.changed}
          />
          <label className="whitespace-nowrap text-body/80 mb-2 inline-block pl-[0.15rem] hover:cursor-pointer" htmlFor={fieldConfig.name}>
            {fieldConfig.label}
          </label>
        </div>
      );
      break;
    case 'section':
      element = (
        <div className="mb-4 mt-2 text-sm relative flex items-center">
          <h3 className="pr-2 text-base text-black/60 whitespace-nowrap">{fieldConfig.value}</h3>
          <span className="w-full  border-t border-body/10"></span>
        </div>
      );
      break;
  }
  return (
    /*  ${fieldConfig.name === 'gender'  && ' col-span-2 '} */
    <>
      {fieldConfig.type !== 'hidden' && (
        <div className={`field-wrapper mb-4 lg:mb-0 ${fieldConfig.colspan ? fieldConfig.colspan : ''}`}>
          {fieldConfig.label && fieldConfig.type !== 'checkbox' && fieldConfig.type !== 'photo' && (
            <label className={`block whitespace-nowrap text-body/80 mb-2 ${fieldConfig.hidelabel ? 'invisible' : ''}`} htmlFor={fieldConfig.id}>
              {fieldConfig.label}
              {fieldConfig.validation?.required && <span className="pl-0.5">*</span>}
            </label>
          )}
          {element}
          {!fieldConfig.valid && fieldConfig.touched && <span className="text-red-500 text-xs italic block mt-1">Enter valid {fieldConfig.label}</span>}
          {/*   {fieldConfig.valid && fieldConfig.helper &&
                    <span className='text-primary text-xs italic block mt-1'>{fieldConfig.label}: {fieldConfig.helper}</span>
                } */}
        </div>
      )}

      {fieldConfig.type === 'hidden' && <>{element}</>}
    </>
  );
}
