import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { RecoilRoot } from 'recoil';
/* Language settings */
import './i18n';

const root = ReactDOM.createRoot(document.getElementById('root'));

/* END OF IMPORT COMPORNENTS */
const userData = JSON.parse(localStorage.getItem('_userData'));
const USER_TYPE = userData && userData.data.user_type;
/* 
let THEME;

if(USER_TYPE && USER_TYPE.toLowerCase() !== 'admin' ){

 // console.log(USER_TYPE.toLowerCase()}.scss`)
  //require(`./${USER_TYPE.toLowerCase()}.scss`);

   THEME = React.lazy(() => import(`./${USER_TYPE.toLowerCase()}`));
} */
const THEME = USER_TYPE && USER_TYPE.toLowerCase() !== 'admin' ? React.lazy(() => import(`./${USER_TYPE.toLowerCase()}`)) : null;

const ThemeSelector = ({ children }) => {
  // const CHOSEN_THEME = localStorage.getItem('TYPE_OF_THEME') || TYPE_OF_THEME.DEFAULT;
  return (
    <>
      <React.Suspense fallback={<></>}>
        <THEME />
      </React.Suspense>
      {children}
    </>
  );
};

root.render(
  <React.StrictMode>
    <RecoilRoot>
      {THEME ? (
        <ThemeSelector>
          <App />
        </ThemeSelector>
      ) : (
        <App />
      )}
    </RecoilRoot>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
