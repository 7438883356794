import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <>
        <footer className="md:flex w-full z-10 bg-bg border-t border-black/10 text-body absolute bottom-0 left-0">
          <div className="container mx-auto px-8 py-6 md:flex justify-between items-center text-center">
            <span className="text-sm ml-auto">
              © 2023 SportUnleash LLC. Powered by{' '}
              <a className="hover:text-primary" target="_blank" href="http://ebeyonds.com">
                eBEYONDS
              </a>
            </span>
          </div>
        </footer>
      </>
    );
  }
}

export default Footer;
