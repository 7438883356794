import React, { Component } from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Drawer from './../components/Drawer';
import Footer from './../components/Footer';
import Assets from './../components/Assets';
import Breadcrumb from './../components/Breadcrumb';
import { withTranslation } from 'react-i18next';

class ResourceBank extends Component {
  constructor(props) {
    super(props);

    this.state = {
      active: true,
      isLoading: false,
      isError: false,
      data: [],
    };
  }

  render() {
    const { t } = this.props;
    return (
      <HelmetProvider>
        <div>
          <Helmet>
            <title>
              {t('general.sportunleash')} - {t('training_resources.title')}
            </title>
          </Helmet>
          <div className="container mx-auto lg:flex  bg-bg">
            <Drawer />
            <div className="w-full md:w-[calc(100%-84px)] 2xl:w-full md:ml-[84px] 2xl:ml-0 md:p-4 lg:p-8 min-h-screen relative pb-[90px] lg:pb-[100px] md:pb-[100px]">
              <Breadcrumb />
              <div className="flex  items-center">
                <h1 className="text-xl md:text-3xl mb-4 font-bold italic">{t('training_resources.title')}</h1>
              </div>
              <Assets />
              <Footer />
            </div>
          </div>
        </div>
      </HelmetProvider>
    );
  }
}

export default withTranslation()(ResourceBank);
