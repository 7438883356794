import { useState } from 'react';
import { useAsyncDebounce } from 'react-table';
import { BiSearch } from 'react-icons/bi';

export const GlobalFilter = ({ globalFilter, setGlobalFilter }) => {
  const [value, setValue] = useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <div className=" mb-8 flex">
      <div className="ml-auto shadow relative overflow-hidden rounded-md border border-black/10  bg-white w-96">
        <BiSearch className="absolute left-2.5 top-3 text-xl text-icon" />
        <input
          className="pl-10 p-2.5 w-full"
          value={value || ''}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
          }}
          placeholder={`Search...`}
        />
      </div>
    </div>
  );
};
