import React, { useState, useCallback, useMemo, useEffect } from 'react';
import { getCountries } from '../services/getCountries';
import TablePagination from './TablePagination';
import { BsEyeFill } from 'react-icons/bs';
import { Link } from 'react-router-dom';
import { MdContentCopy, MdOutlineInsertDriveFile, MdOutlineNoteAdd, MdOutlineNoteAlt, MdOutlineSchool } from 'react-icons/md';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import * as dayjs from 'dayjs';
import { FaWaveSquare } from 'react-icons/fa';

function CountryDatatable() {
  const [data, setData] = useState([]);
  const [countries, setCountries] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pageCount, setPageCount] = useState(0);
  const [totalRow, setTotalRow] = useState(0);

  const fetchData = useCallback(async (pageSize, pageIndex, search, order, filters) => {
    setLoading(true);
    const queryOptions = {
      page: pageIndex + 1,
      per_page: pageSize,
      search: search,
      order: order,
      filters: filters,
    };
    const items = await getCountries(queryOptions);
    const data = items && items.data ? items.data : [];

    console.log(items);
    setData(items);
    setPageCount(Math.ceil(items.length / pageSize));
    setTotalRow(items.length);
    setLoading(false);
  }, []);

  const columns = useMemo(
    () => [
      /*  {
                Header: "",
                accessor: "id",
                Cell: ({ row }) => {
                return  <div className='id'>
                <div className="mb-[0.125rem] block min-h-[1.5rem] pl-[1.5rem]">
                      <input
                        className="relative float-left mt-[0.15rem] mr-[6px] -ml-[1.5rem] h-[1.125rem] w-[1.125rem] appearance-none rounded-[0.25rem] border-[0.125rem] border-solid border-[rgba(0,0,0,0.25)] bg-white outline-none before:pointer-events-none before:absolute before:h-[0.875rem] before:w-[0.875rem] before:scale-0 before:rounded-full before:bg-transparent before:opacity-0 before:shadow-[0px_0px_0px_13px_transparent] before:content-[''] checked:border-primary checked:bg-primary checked:before:opacity-[0.16] checked:after:absolute checked:after:ml-[0.25rem] checked:after:-mt-px checked:after:block checked:after:h-[0.8125rem] checked:after:w-[0.375rem] checked:after:rotate-45 checked:after:border-[0.125rem] checked:after:border-t-0 checked:after:border-l-0 checked:after:border-solid checked:after:border-white checked:after:bg-transparent checked:after:content-[''] hover:cursor-pointer hover:before:opacity-[0.04] hover:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:shadow-none focus:transition-[border-color_0.2s] focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[0px_0px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-[0.875rem] focus:after:w-[0.875rem] focus:after:rounded-[0.125rem] focus:after:bg-white focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary checked:focus:before:scale-100 checked:focus:before:shadow-[0px_0px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s] checked:focus:after:ml-[0.25rem] checked:focus:after:-mt-px checked:focus:after:h-[0.8125rem] checked:focus:after:w-[0.375rem] checked:focus:after:rotate-45 checked:focus:after:rounded-none checked:focus:after:border-[0.125rem] checked:focus:after:border-t-0 checked:focus:after:border-l-0 checked:focus:after:border-solid checked:focus:after:border-white checked:focus:after:bg-transparent"
                        type="checkbox"
                        value=""/>
                    </div>
                  </div>
                },
                disableSortBy: true,
            }, */
      {
        Header: 'Student Name',
        accessor: 'first_name',
        Cell: ({ row }) => {
          return (
            <>
              <span className="text-body block font-semibold mb-0.5 ">
                {row.original.first_name} {row.original.last_name}
              </span>
              <span
                className="text-body italic bg-primary/10 rounded-xl px-3 py-1 text-xs inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20"
                title="Copy to clipboard"
                onClick={() => {
                  navigator.clipboard.writeText(row.original.child_id);
                  toast.info('Student ID Copied to Clipboard', {
                    position: 'bottom-center',
                    hideProgressBar: true,
                    theme: 'dark',
                    autoClose: 1000,
                    theme: 'colored',
                  });
                }}
              >
                {row.original.child_id}
                <MdContentCopy className="text-primary text-sm  ml-1" />
              </span>
            </>
          );
        },
      },
      {
        Header: 'Added Date',
        accessor: 'created_at',
        Cell: ({ value }) => {
          return dayjs(value).format('DD MMM YYYY');
        },
      },
      {
        Header: 'Date of Birth',
        accessor: 'date_of_birth',
        Cell: ({ value }) => {
          return dayjs(value).format('DD MMM YYYY');
        },
      },
      {
        Header: "Mother's Name",
        accessor: 'mothers_name',
      },
      {
        Header: "Father's Name",
        accessor: 'fathers_name',
      },
      {
        Header: 'Test Status',
        accessor: 'status',
        Cell: ({ value }) => {
          return <>{value === 'completed' ? <span className="text-green-500">Completed</span> : <span className="text-orange-500">Pending</span>}</>;
        },
      },
      {
        Header: '',
        accessor: 'test',
        disableSortBy: true,
        Cell: ({ row }) => {
          return (
            <>
              {row.original.status === 'completed' ? (
                <span
                  className="text-primary italic bg-primary/10 rounded-xl px-3 py-1 text-sm inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20"
                  title="Copy to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(row.original.child_id);
                  }}
                >
                  <MdOutlineInsertDriveFile className="text-primary text-base  mr-1" />
                  View test
                </span>
              ) : (
                <Link
                  to={'/test-management/create'}
                  state={row.original.child_id}
                  className="text-primary italic bg-primary/10 rounded-xl px-3 py-1 text-sm inline-flex items-center cursor-pointer focus:bg-primary/40 hover:bg-primary/20"
                  title="Copy to clipboard"
                  onClick={() => {
                    navigator.clipboard.writeText(row.original.child_id);
                  }}
                >
                  <MdOutlineNoteAdd className="text-primary text-base  mr-1" />
                  Add test
                </Link>
              )}
            </>
          );
        },
      },
      {
        Header: '',
        accessor: 'actions',
        disableSortBy: true,
        Cell: ({ row }) => {
          //query: {paramobj: JSON.stringify(row)}
          // console.warn(JSON.stringify(row));
          //  console.warn(row);

          return (
            <div className="flex gap-2 text-xl items-center justify-end text-icon">
              {/*  <div className="flex justify-center">
                                <div>
                                <input
                                    className="mt-[0.3rem] mr-2 h-3.5 w-8 appearance-none rounded-[0.4375rem] bg-[rgba(0,0,0,0.25)] outline-none before:pointer-events-none before:absolute before:h-3.5 before:w-3.5 before:rounded-full before:bg-transparent before:content-[''] after:absolute after:z-[2] after:-mt-[0.1875rem] after:h-5 after:w-5 after:rounded-full after:border-none after:bg-icon after:shadow-[0_0px_3px_0_rgb(0_0_0_/_7%),_0_2px_2px_0_rgb(0_0_0_/_4%)] after:transition-[background-color_0.2s,transform_0.2s] after:content-[''] checked:bg-primary/20 checked:after:absolute checked:after:z-[2] checked:after:-mt-[3px] checked:after:ml-[1.0625rem] checked:after:h-5 checked:after:w-5 checked:after:rounded-full checked:after:border-none checked:after:bg-primary checked:after:shadow-[0_3px_1px_-2px_rgba(0,0,0,0.2),_0_2px_2px_0_rgba(0,0,0,0.14),_0_1px_5px_0_rgba(0,0,0,0.12)] checked:after:transition-[background-color_0.2s,transform_0.2s] checked:after:content-[''] hover:cursor-pointer focus:before:scale-100 focus:before:opacity-[0.12] focus:before:shadow-[3px_-1px_0px_13px_rgba(0,0,0,0.6)] focus:before:transition-[box-shadow_0.2s,transform_0.2s] focus:after:absolute focus:after:z-[1] focus:after:block focus:after:h-5 focus:after:w-5 focus:after:rounded-full focus:after:content-[''] checked:focus:border-primary checked:focus:bg-primary/30 checked:focus:before:ml-[1.0625rem] checked:focus:before:scale-100 checked:focus:before:shadow-[3px_-1px_0px_13px_#3b71ca] checked:focus:before:transition-[box-shadow_0.2s,transform_0.2s]"
                                    type="checkbox"
                                    role="switch"
                                    />
                                </div>
                            </div> */}
              {/*   <button className="btn btn-xs btn-info">
                                <MdDelete className='cursor-pointer'/>
                            </button> */}
              <Link to={`/student-management/${row.original.child_id}`} state={row.original} className="btn btn-xs btn-error hover:text-primary">
                <BsEyeFill className="cursor-pointer" />
              </Link>
            </div>
          );
        },
      },
    ],
    [],
  );

  const filters = useMemo(
    () => [
      {
        label: 'Status',
        key: 'status',
        value: null,
        selected: null,
        icon: <FaWaveSquare />,
        options: [
          {
            id: 'verified',
            name: 'Verified',
          },
          {
            id: 'unverified',
            name: 'Unverified',
          },
        ],
      },
    ],
    [],
  );

  return (
    <section className="relative">
      <TablePagination filters={filters} columns={columns} data={data} fetchData={fetchData} loading={loading} pageCount={pageCount} totalRow={totalRow} />
      <ToastContainer />
    </section>
  );
}

export default CountryDatatable;
