import React, { useState } from 'react';
import { AiOutlineSearch } from 'react-icons/ai';
import Select, { components, MultiValueGenericProps } from 'react-select';
import { getCountries } from '../services/getCountries';
import Flags from 'country-flag-icons/react/3x2';
const { Option } = components;

const CustomOption = (props) => (
  <Option {...props}>
    <div className="flex items-center">
      {setFlags(props.data.alpha_2)}
      <span className="text-sm">{props.data.name}</span>
    </div>
  </Option>
);
const setFlags = (key) => {
  const Flag = Flags[key.toUpperCase()];
  return <Flag className="w-6 h-auto mr-2" />;
};

const MultiValueLabel = (props) => {
  return (
    <div content={'Customise your multi-value label component!'}>
      <components.MultiValueLabel {...props} />
    </div>
  );
};

const Control = ({ children, ...props }) => {
  return (
    <components.Control {...props}>
      <span>
        <AiOutlineSearch className="text-xl text-gray-400 ml-2" />
      </span>
      {children}
    </components.Control>
  );
};

export default function App(props) {
  const [countryList, setCountryList] = useState([]);

  console.log(props);

  const selectCountries = (data) => {
    props.chooseCountries(data, props.close);
  };

  React.useEffect(() => {
    const fetchData = async () => {
      const countryList = await getCountries();
      setCountryList(countryList);
    };

    // call the function
    fetchData();
  }, []);

  return (
    <Select
      menuIsOpen={props.menuIsOpen}
      defaultMenuIsOpen={props.defaultMenuIsOpen}
      openMenuOnFocus={props.openMenuOnFocus}
      maxMenuHeight={props.maxMenuHeight}
      closeMenuOnSelect={props.closeMenuOnSelect}
      isMulti={props.isMulti}
      className={props.className}
      hideSelectedOptions={true}
      getOptionLabel={(option) => option.name}
      getOptionValue={(option) => option.id}
      //className="react-select-container"
      classNamePrefix="react-select"
      onChange={selectCountries}
      placeholder="Search..."
      components={{ Option: CustomOption, Control, MultiValueLabel }}
      options={countryList}
    />
  );
}
